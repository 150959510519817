.App {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

main {
  min-height: 100vh;
  margin: 200px auto 0;
  padding: 2em 0;
  background-image: url("./assets/images/pexels-aleksandar-pasaric-1694000.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-size: 85% auto;
}
