.input {
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  border-radius: 3px;
  outline: none;
  border: none;
}

.input:focus {
  background-color: var(--grey-modal);
  color: var(--yellow-modal);
  border: 1px solid var(--yellow-modal);
  caret-color: var(--yellow-modal);
}

.input:focus::placeholder {
  color: var(--white);
}

.input--expand {
  width: 100%;
}

.input--error {
  border: 1px solid var(--red-modal);
}

.input--error::placeholder {
  color: var(--red-modal);
}

.input__errors {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.6em 0;
  color: var(--red-modal);
  text-align: left;
}
