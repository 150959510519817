.cards {
  margin: 5em auto;
  width: 70%;
}

.cards__header {
  position: relative;
  height: 700px;
}

.cards__image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.cards__header-info {
  position: absolute;
  bottom: 15%;
  left: 50%;
  width: 40%;
  transform: translateX(-50%);
  text-align: center;
}

.cards__header-info h2 {
  font-size: 3rem;
  color: var(--black);
}

.cards__header-info a {
  padding: 15px 32px;
  color: var(--white);
  background-color: var(--blue-color);
  border-radius: 2px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cards__header-info a:hover {
  background-color: #177afb;
}

.cards__body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 3em;
  padding-bottom: 1em;
  background: linear-gradient(
    to bottom,
    rgb(31, 64, 85) 0%,
    rgb(94, 108, 136) 100%
  );
}
