.actor {
  color: var(--grey-color);
  margin-bottom: 10em;
}

.actor h3 {
  margin-bottom: 0;
  padding: 0.5em 0.6em;
  color: var(--white);
  background-color: var(--black);
  border-bottom: 1px solid var(--gold-color);
  font-size: 3rem;
}

.actor__details {
  display: flex;
  padding: 1em 2em;
  background-color: var(--grey-dark);
}

.actor__details div {
  flex: 1;
}

.actor__detail {
  font-size: 1.3rem;
}
