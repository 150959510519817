.search__form {
  display: flex;
  justify-content: flex-end;
}

.search__input {
  width: 200px;
  height: 40px;
  padding: 0 0 0 1em;
  outline: none;
  border: none;
  border-radius: 5px 0px 0px 5px;
  background-color: var(--grey-dark);
  font-size: 1rem;
  color: var(--white);
}

.search__input:focus {
  filter: brightness(120%);
}

.search__button {
  height: 40px;
  background-color: var(--grey-dark);
  border-radius: 0px 5px 5px 0px;
}

.search__button ion-icon {
  color: var(--grey-color);
  font-size: 26px;
}

.search__input:focus + .search__button {
  filter: brightness(120%);
}

.button-group {
  width: fit-content;
  margin-top: 1.5em;
  margin-left: auto;
  color: var(--grey-dark);
  font-size: 0.9rem;
  font-weight: bold;
}

.login__button {
  background-color: #000;
  color: var(--grey-color);
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: color 0.8s ease;
}

.login__button:hover,
.login__button:focus {
  color: var(--white);
}

.login__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 0.2em;
}

.login__user {
  width: 150px;
  display: flex;
  align-items: center;
}

.login__user ion-icon {
  padding-top: 5px;
  font-size: 30px;
}

.login__user span:nth-child(2) {
  padding-top: 2px;
  padding-left: 5px;
  text-align: left;
  overflow-x: hidden;
}
