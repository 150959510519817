.card {
  width: 248px;
  height: 413px;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 4px;
  overflow: hidden;
}

.card__image {
  height: 279px;
  overflow: hidden;
}

.card__image img {
  width: 100%;
  height: 100%;
}

.card__content {
  position: relative;
  height: 104px;
  padding: 36px 20px 20px;
  background-color: var(--black-card);
}

.card__decal-right {
  position: absolute;
  right: 20px;
  top: 0;
  width: 12px;
  height: 8px;
}

.card__decal-right::before,
.card__decal-right::after {
  content: "";
  position: absolute;
  border-radius: 0 0 2px 2px;
  background: #ffffff33;
  height: 100%;
  width: 4px;
}

.card__decal-right::after {
  right: -5px;
}

.card__decal-left {
  position: absolute;
  z-index: 0;
  top: 20px;
  left: 9px;
  width: 24px;
}

.card__decal-left::before,
.card__decal-left::after {
  content: "";
  position: absolute;
  border-radius: 2px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
  transition: box-shadow 0.3s ease;
}

.card__decal-left::before {
  width: 16px;
}

.card__decal-left::after {
  left: 32px;
  width: 4px;
}

.card__title {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: var(--white);
}

.card__gradient {
  background: linear-gradient(
    to bottom,
    rgba(26, 26, 26, 0.001) 50%,
    #1a1a1a 200%
  );
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

.card:hover > .card__image img {
  transform: scale(1.02);
}

.card:hover > .card__content > .card__decal-left::before,
.card:hover > .card__content > .card__decal-left::after {
  border-top: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
  box-shadow: 0 0 6px 2px;
}

.card__footer {
  position: relative;
  height: 6px;
  background-color: transparent;
}

.card__footer::before,
.card__footer::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  border-top: 6px solid #1a1a1a;
}

.card__footer::before {
  left: 0;
  width: 58%;
  border-right: 6px solid transparent;
}

.card__footer::after {
  right: 0;
  width: 40px;
  border-left: 6px solid transparent;
}
