:root {
  --white: #fff;
  --grey-color: #7a7a7a;
  --grey-dark: #242424;
  --black: #000;

  --background-modal: #181818;
  --yellow-modal: #edd700;
  --grey-modal: #484848;
  --blue-modal: #3f6dac;
  --red-modal: #ff0000;

  --border-color: #333;

  --gold-color: #dcae50;
  --blue-color: #016cf9;
  --orange-color: #d57026;
  --brown-color: #403321;

  --black-card: #1a1a1a;
  --grey-card: #5d645e;
  --sea-color: #1f4055;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Stars";
  src: url("./fonts/star.woff") format("woff");
}

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: Stars, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
  color: var(--white);
}

a {
  color: var(--grey-color);
  text-decoration: none;
}
