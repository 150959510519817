.preview {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.preview__card {
  position: relative;
  width: 200px;
  height: 80px;
  margin-right: 1.5em;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
}

.preview__card * {
  pointer-events: none;
}

.preview__progress,
.preview__title {
  position: relative;
  z-index: 10;
}

.preview__progress {
  opacity: 0;
  height: 2px;
}

.preview__progress--animated {
  animation: progress-animation 6s linear;
}

.preview__progress--selected {
  opacity: 1;
}

.preview__progress--blue {
  background-color: var(--blue-color);
}

.preview__progress--orange {
  background-color: var(--orange-color);
}

.preview__progress--gold {
  background-color: var(--gold-color);
}

.preview__background {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  filter: brightness(50%);
}

.preview__background--blur {
  filter: brightness(25%) blur(2px);
}

.preview__title {
  display: inline-block;
  padding: 1em 1em 0;
  font-size: 0.87rem;
}

@keyframes progress-animation {
  0% {
    opacity: 1;
    width: 0%;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
